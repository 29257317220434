import * as requestFromServer from "./coursesCategoryCrud";
import { coursesCategorySlice, callTypes } from "./coursesCategorySlice";

const { actions } = coursesCategorySlice;

export const fetchCoursesCategory = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findCoursesCategory(queryParams)
    .then((response) => {
      const { count, data } = response.data;
      dispatch(actions.coursesCategoryFetched({ count, data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find courses";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchCourseCategory = (id) => (dispatch) => {
  if (!id) {
    return dispatch(
      actions.courseCategoryFetched({ courseCategoryForEdit: undefined })
    );
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getCourseCategoryById(id)
    .then((response) => {
      const course = response.data;
      dispatch(
        actions.courseCategoryFetched({ courseCategoryForEdit: course })
      );
    })
    .catch((error) => {
      error.clientMessage = "No puedo encontrar el curso";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteCourseCategory = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteCourseCategory(id)
    .then((response) => {
      dispatch(actions.courseCategoryDeleted({ id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete course";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createCourseCategory = (courseForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createCourseCategory(courseForCreation)
    .then((response) => {
      const { course } = response.data;
      dispatch(actions.courseCategoryCreated({ course }));
    })
    .catch((error) => {
      error.clientMessage = "No se puede crear el curso";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateCourseCategory = (courses_category) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateCourseCategory(courses_category)
    .then(() => {
      dispatch(actions.courseCategoryUpdated({ courses_category }));
    })
    .catch((error) => {
      console.log("error", error);
      error.clientMessage = "No se puede actualizar el curso";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

// export const updateProductsStatus = (ids, status) => dispatch => {
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .updateStatusForProducts(ids, status)
//     .then(() => {
//       dispatch(actions.productsStatusUpdated({ ids, status }));
//     })
//     .catch(error => {
//       error.clientMessage = "Can't update products status";
//       dispatch(actions.catchError({ error, callType: callTypes.action }));
//     });
// };

// export const deleteProducts = ids => dispatch => {
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .deleteProducts(ids)
//     .then(() => {
//       dispatch(actions.productsDeleted({ ids }));
//     })
//     .catch(error => {
//       error.clientMessage = "Can't delete products";
//       dispatch(actions.catchError({ error, callType: callTypes.action }));
//     });
// };

// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../_redux/coursesActions";
import * as uiHelpers from "../CoursesUIHelpers";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
} from "../../../../_metronic/_helpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../_metronic/_partials/controls";
import { useCoursesUIContext } from "../CoursesUIContext";

export function CoursesTable() {
  // console.log(history);
  // Courses UI Context
  const coursesUIContext = useCoursesUIContext();
  const coursesUIProps = useMemo(() => {
    return {
      ids: coursesUIContext.ids,
      setIds: coursesUIContext.setIds,
      queryParams: coursesUIContext.queryParams,
      setQueryParams: coursesUIContext.setQueryParams,
      openEditCoursePage: coursesUIContext.openEditCoursePage,
      openDeleteCourseDialog: coursesUIContext.openDeleteCourseDialog,
      openCourseModulePage: coursesUIContext.openCourseModulePage,
    };
  }, [coursesUIContext]);

  // Getting curret state of courses list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.courses }),
    shallowEqual
  );
  const { totalCount, entities, listLoading } = currentState;
  // Courses Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    coursesUIProps.setIds([]);
    // server call by queryParams
    dispatch(actions.fetchCourses(coursesUIProps.queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coursesUIProps.queryParams, dispatch]);
  // Table columns
  const columns = [
    {
      dataField: "category",
      text: "Categoria",
      // sort: true,
      // sortCaret: sortCaret,
    },
    {
      dataField: "es_date",
      text: "Fecha",
      // sort: true,
      // sortCaret: sortCaret,
    },
    {
      dataField: "title",
      text: "Titulo",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "duration",
      text: "Duración (hrs)",
      // sort: true,
      // sortCaret: sortCaret,
    },
    // {
    //   dataField: "price",
    //   text: "Precio",
    //   sort: true,
    //   sortCaret: sortCaret,
    // },
    {
      dataField: "description",
      text: "Descripción",
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.DescriptionColumnFormatter,
    },
    {
      dataField: "short_description",
      text: "Descripción C.",
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.DescriptionColumnFormatter,
    },
    {
      dataField: "targets",
      text: "Objetivos",
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.TargetColumnFormatter,
    },
    {
      dataField: "customers",
      text: "Dirigido",
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.CustomerColumnFormatter,
    },
    {
      dataField: "includes",
      text: "Incluye",
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.IncludesColumnFormatter,
    },
    {
      dataField: "btn_label",
      text: "Botón Titulo",
      sort: true,
      sortCaret: sortCaret,
    },
    // {
    //   dataField: "btn_url",
    //   text: "Botón Url",
    //   // sort: true,
    //   // sortCaret: sortCaret,
    // },
    // {
    //   dataField: "module",
    //   text: "Modulos",
    //   // sort: true,
    //   // sortCaret: sortCaret,
    // },

    {
      dataField: "image",
      text: "Imagen",
      formatter: columnFormatters.ImageColumnFormatter,
    },
    {
      dataField: "image_details",
      text: "Img. Detalles",
      formatter: columnFormatters.ImageColumnFormatter,
    },
    {
      dataField: "action",
      text: "Opciones",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditCoursePage: coursesUIProps.openEditCoursePage,
        openDeleteCourseDialog: coursesUIProps.openDeleteCourseDialog,
        openCourseModulePage: coursesUIProps.openCourseModulePage,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Mostrando {from} a {to} de {size} Registros
    </span>
  );

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: coursesUIProps.queryParams.pageSize,
    page: coursesUIProps.queryParams.pageNumber,
    paginationTotalRenderer: customTotal,
  };

  const entitiesClean = (entities || []).filter(function(element) {
    return element !== undefined;
  });
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                bordered={false}
                remote
                keyField="id"
                data={entitiesClean === null ? [] : entitiesClean}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  coursesUIProps.setQueryParams
                )}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entitiesClean} />
                <NoRecordsFoundMessage entities={entitiesClean} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}

import React from "react";
import { Route } from "react-router-dom";
import { useSubheader } from "../../../_metronic/layout";
import { CoursesCategoryLoadingDialog } from "./courses-category-loading-dialog/CoursesCategoryLoadingDialog";
import { CoursesCategoryUIProvider } from "./CoursesCategoryUIContext";
import { CourseCategoryDeleteDialog } from "./course-category-delete-dialog/CourseCategoryDeleteDialog";
import { CoursesCategoryCard } from "./CoursesCategoryCard";

export default function CourseCategoryPage({ history }) {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Listado Categoria de Curso");

  const CourseCategoryUIEvents = {
    newCourseCategoryButtonClick: () => {
      history.push("/curso/categoria/nuevo");
    },
    openEditCourseCategoryPage: (id) => {
      history.push(`/curso/categoria/${id}/editar`);
    },
    openDeleteCourseCategoryDialog: (id) => {
      history.push(`/curso/categoria/${id}/eliminar`);
    },
  };

  return (
    <CoursesCategoryUIProvider coursesCategoryUIEvents={CourseCategoryUIEvents}>
      <CoursesCategoryLoadingDialog />
      <Route path="/curso/categoria/:id/eliminar">
        {({ history, match }) => (
          <CourseCategoryDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/curso/categoria/");
            }}
          />
        )}
      </Route>
      <CoursesCategoryCard />
    </CoursesCategoryUIProvider>
  );
}

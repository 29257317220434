/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { shallowEqual, useSelector } from "react-redux";
import * as actions from "../_redux/coursesCategoryActions";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { CourseCategoryEditForm } from "./CourseCategoryEditForm";
import { useSubheader } from "../../../../_metronic/layout";
import { ModalProgressBar } from "../../../../_metronic/_partials/controls";

const initCourse = {
  id: undefined,
  title: "",
};

export function CourseCategoryEdit({
  history,
  match: {
    params: { id },
  },
}) {
  // Subheader
  const suhbeader = useSubheader();

  // Tabs
  // const [tab, setTab] = useState("basic");
  const [title, setTitle] = useState("");
  const dispatch = useDispatch();
  // const layoutDispatch = useContext(LayoutContext.Dispatch);
  const { actionsLoading, courseCategoryForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.courses_category.actionsLoading,
      courseCategoryForEdit: state.courses_category.courseCategoryForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetchCourseCategory(id));
  }, [id, history, dispatch]);

  useEffect(() => {
    let _title = id ? "" : "Nueva Categoria de Curso";

    if (courseCategoryForEdit && id) {
      _title = "Editar Categoria de Curso";
    }

    setTitle(_title);
    suhbeader.setTitle(_title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseCategoryForEdit, id]);

  const saveCourseCategory = (values) => {
    // console.log("data3", values);
    let formData = new FormData();
    formData.append("title", values.title);

    if (!id) {
      dispatch(actions.createCourseCategory(formData)).then(() =>
        backToCoursesCategoryList()
      );
    } else {
      formData.append("id", values.id);
      dispatch(actions.updateCourseCategory(formData)).then(() =>
        backToCoursesCategoryList()
      );
    }
  };

  const btnRef = useRef();
  const saveCourseCategoryClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };

  const backToCoursesCategoryList = () => {
    history.push(`/curso/categoria/listado`);
  };

  return (
    <Card>
      {actionsLoading && <ModalProgressBar />}
      <CardHeader title={title}>
        <CardHeaderToolbar>
          <button
            type="button"
            onClick={backToCoursesCategoryList}
            className="btn btn-light"
          >
            <i className="fa fa-arrow-left"></i>
            Regresar
          </button>
          {`  `}
          <button
            type="submit"
            className="btn btn-primary ml-2"
            onClick={saveCourseCategoryClick}
          >
            Guardar
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="mt-5">
          {/* {tab === "basic" && ( */}
          <CourseCategoryEditForm
            actionsLoading={actionsLoading}
            courseCategory={courseCategoryForEdit || initCourse}
            btnRef={btnRef}
            saveCourseCategory={saveCourseCategory}
          />
        </div>
      </CardBody>
    </Card>
  );
}

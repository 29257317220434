import axios from "axios";

export const BASE_URL = "https://api.v1.tecnologiacervecera.com";
export const HEADER_URL = `${BASE_URL}/header`;
export const HEADER_URL_ADMIN = `${BASE_URL}/header_admin`;
export const HEADERS_URL = `${BASE_URL}/headers`;

export const getNameField = (field) => {
  switch (field) {
    case "full_date":
      return "date";
    default:
      return field;
  }
};

// READ
export function getAllHeaders() {
  return axios.get(HEADERS_URL);
}

export function getHeaderById(headerId) {
  return axios.get(`${HEADER_URL_ADMIN}/${headerId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findHeaders(qp) {
  // console.log("queryParams", qp);
  const params = {
    offset: qp.pageNumber - 1,
    limit: qp.pageSize,
    field: getNameField(qp.sortField),
    order: qp.sortOrder,
    search_fields: qp.searchFields.join(","),
    search: qp.search,
  };
  // console.log("params", params);
  // const response = axios.get(`${headerS_URL}`, { queryParams });
  const response = axios.get(`${HEADERS_URL}`, { params });
  // console.log(response);
  return response;
}

// UPDATE => PUT: update the header on the server
export function updateHeader(header) {
  const id = header.get("id");
  return axios.post(`${HEADER_URL}/${id}`, header, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./CoursesUIHelpers";

const CoursesUIContext = createContext();

export function useCoursesUIContext() {
  return useContext(CoursesUIContext);
}

export const CoursesUIConsumer = CoursesUIContext.Consumer;

export function CoursesUIProvider({ coursesUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    newCourseButtonClick: coursesUIEvents.newCourseButtonClick,
    openEditCoursePage: coursesUIEvents.openEditCoursePage,
    openDeleteCourseDialog: coursesUIEvents.openDeleteCourseDialog,
    openCourseModulePage: coursesUIEvents.openCourseModulePage,
  };

  return (
    <CoursesUIContext.Provider value={value}>
      {children}
    </CoursesUIContext.Provider>
  );
}

import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { HeadersFilter } from "./header-filter/HeadersFilter";
import { HeadersTable } from "./headers-table/HeadersTable";
import { HeadersGrouping } from "./headers-grouping/HeadersGrouping";
import { useHeadersUIContext } from "./HeadersUIContext";

export function HeadersCard() {
  const headersUIContext = useHeadersUIContext();
  const headersUIProps = useMemo(() => {
    return {
      ids: headersUIContext.ids,
      queryParams: headersUIContext.queryParams,
      setQueryParams: headersUIContext.setQueryParams,
      openEditHeaderPage: headersUIContext.openEditHeaderPage,
    };
  }, [headersUIContext]);

  return (
    <Card>
      <CardHeader title="Header">
        <CardHeaderToolbar></CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <HeadersFilter />
        {headersUIProps.ids.length > 0 && (
          <>
            <HeadersGrouping />
          </>
        )}
        <HeadersTable />
      </CardBody>
    </Card>
  );
}

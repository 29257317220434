import React, { Fragment } from "react";

export const IncludesColumnFormatter = (cellContent, row) => {
  let data = (cellContent || "").split("\n");
  const cont = data.length;
  if (cont >= 3) {
    data = data.slice(0, 5);
  }

  return data.map((item, key) => {
    return (
      <Fragment key={key}>
        - {item.length > 10 ? `${item.slice(0, 10)}...` : item}
        <br />
      </Fragment>
    );
  });
};

import React from "react";
import { Route } from "react-router-dom";
import { useSubheader } from "../../../_metronic/layout";
import { CoursesLoadingDialog } from "./courses-loading-dialog/CoursesLoadingDialog";
import { CoursesUIProvider } from "./CoursesUIContext";
import { CourseDeleteDialog } from "./course-delete-dialog/CourseDeleteDialog";
import { CoursesCard } from "./CoursesCard";

export default function CoursePage({ history }) {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Listado Cursos");

  const CourseUIEvents = {
    newCourseButtonClick: () => {
      history.push("/curso/nuevo");
    },
    openEditCoursePage: (id) => {
      history.push(`/curso/${id}/editar`);
    },
    openDeleteCourseDialog: (id) => {
      history.push(`/curso/${id}/eliminar`);
    },
    openCourseModulePage: (id) => {
      history.push(`/curso/modulo/${id}`);
    },
  };

  return (
    <CoursesUIProvider coursesUIEvents={CourseUIEvents}>
      <CoursesLoadingDialog />
      <Route path="/curso/:id/eliminar">
        {({ history, match }) => (
          <CourseDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/curso/");
            }}
          />
        )}
      </Route>
      <CoursesCard />
    </CoursesUIProvider>
  );
}

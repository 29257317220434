import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./CoursesCategoryUIHelpers";

const CoursesCategoryUIContext = createContext();

export function useCoursesCategoryUIContext() {
  return useContext(CoursesCategoryUIContext);
}

export const CoursesCategoryUIConsumer = CoursesCategoryUIContext.Consumer;

export function CoursesCategoryUIProvider({
  coursesCategoryUIEvents,
  children,
}) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    newCourseCategoryButtonClick:
      coursesCategoryUIEvents.newCourseCategoryButtonClick,
    openEditCourseCategoryPage:
      coursesCategoryUIEvents.openEditCourseCategoryPage,
    openDeleteCourseCategoryDialog:
      coursesCategoryUIEvents.openDeleteCourseCategoryDialog,
  };

  return (
    <CoursesCategoryUIContext.Provider value={value}>
      {children}
    </CoursesCategoryUIContext.Provider>
  );
}

import * as requestFromServer from "./headersCrud";
import { headersSlice, callTypes } from "./headersSlice";

const { actions } = headersSlice;

export const fetchHeaders = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findHeaders(queryParams)
    .then((response) => {
      const { count, data } = response.data;
      dispatch(actions.headersFetched({ count, data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find headers";
      // validResponse(error, history)
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchHeader = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.headerFetched({ headerForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getHeaderById(id)
    .then((response) => {
      const header = response.data;
      dispatch(actions.headerFetched({ headerForEdit: header }));
    })
    .catch((error) => {
      error.clientMessage = "No puedo encontrar el header";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateHeader = (header) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateHeader(header)
    .then(() => {
      dispatch(actions.headerUpdated({ header }));
    })
    .catch((error) => {
      console.log("error", error);
      error.clientMessage = "No se puede actualizar el header";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

import React from "react";
import {
  HeaderStatusCssClasses,
  HeadersStatusTitles,
} from "../../HeadersUIHelpers";

export const StatusColumnFormatter = (cellContent, row) => (
  <span
    className={`label label-lg label-light-${
      HeaderStatusCssClasses[row.status]
    } label-inline`}
  >
    {HeadersStatusTitles[row.status]}
  </span>
);

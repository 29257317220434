import React, { useMemo } from "react";
import { useHeadersUIContext } from "../HeadersUIContext";

export function HeadersGrouping() {
  // Headers UI Context
  const headersUIContext = useHeadersUIContext();
  const headersUIProps = useMemo(() => {
    return {
      ids: headersUIContext.ids,
      setIds: headersUIContext.setIds,
      openFetchHeadersDialog: headersUIContext.openFetchProductsDialog,
      openUpdateHeadersStatusDialog:
        headersUIContext.openUpdateHeadersStatusDialog,
    };
  }, [headersUIContext]);

  return (
    <div className="form">
      <div className="row align-items-center form-group-actions margin-top-20 margin-bottom-20">
        <div className="col-xl-12">
          <div className="form-group form-group-inline">
            <div className="form-label form-label-no-wrap">
              <label className="-font-bold font-danger-">
                <span>
                  Selected records count: <b>{headersUIProps.ids.length}</b>
                </span>
              </label>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-light-primary font-weight-bolder font-size-sm"
                onClick={headersUIProps.openFetchHeadersDialog}
              >
                <i className="fa fa-stream"></i> Fetch Selected
              </button>
              &nbsp;
              <button
                type="button"
                className="btn btn-light-primary font-weight-bolder font-size-sm"
                onClick={headersUIProps.openUpdateHeadersStatusDialog}
              >
                <i className="fa fa-sync-alt"></i> Update Status
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

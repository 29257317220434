import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { CoursesCategoryFilter } from "./courses-category-filter/CoursesCategoryFilter";
import { CoursesCategoryTable } from "./courses-category-table/CoursesCategoryTable";
import { CoursesCategoryGrouping } from "./courses-category-grouping/CoursesCategoryGrouping";
import { useCoursesCategoryUIContext } from "./CoursesCategoryUIContext";

export function CoursesCategoryCard() {
  const coursesCategoryUIContext = useCoursesCategoryUIContext();
  const coursesCategoryUIProps = useMemo(() => {
    return {
      ids: coursesCategoryUIContext.ids,
      queryParams: coursesCategoryUIContext.queryParams,
      setQueryParams: coursesCategoryUIContext.setQueryParams,
      newCourseCategoryButtonClick:
        coursesCategoryUIContext.newCourseCategoryButtonClick,
      openDeleteCourseCategoryDialog:
        coursesCategoryUIContext.openDeleteCourseCategoryDialog,
      openEditCourseCategoryPage:
        coursesCategoryUIContext.openEditCourseCategoryPage,
    };
  }, [coursesCategoryUIContext]);

  return (
    <Card>
      <CardHeader title="Categorias de Cursos">
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={coursesCategoryUIProps.newCourseCategoryButtonClick}
          >
            Nueva Categoria
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <CoursesCategoryFilter />
        {coursesCategoryUIProps.ids.length > 0 && (
          <>
            <CoursesCategoryGrouping />
          </>
        )}
        <CoursesCategoryTable />
      </CardBody>
    </Card>
  );
}

// import React, { Suspense, lazy } from "react";
import React, { Suspense } from "react";
// import { Redirect, Switch, Route } from "react-router-dom";
import { Redirect, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
import { DashboardPage } from "./pages/DashboardPage";
import SlideshowPage from "./pages/slideshows/SlideshowPage";
import { SlideshowEdit } from "./pages/slideshows/slideshow-edit/SlideshowEdit";
import CoursePage from "./pages/courses/CoursePage";
import { CourseEdit } from "./pages/courses/course-edit/CourseEdit";
import CourseCategoryPage from "./pages/courses_category/CourseCategoryPage";
import { CourseCategoryEdit } from "./pages/courses_category/course-category-edit/CourseCategoryEdit";
import Logout from "./modules/Auth/pages/Logout";
import { HeaderEdit } from "./pages/header/header-edit/HeaderEdit";
import HeaderPage from "./pages/header/HeaderPage";

// const GoogleMaterialPage = lazy(() =>
//   import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
// );
// const ReactBootstrapPage = lazy(() =>
//   import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
// );
// const ECommercePage = lazy(() =>
//   import("./modules/ECommerce/pages/eCommercePage")
// );
// const UserProfilepage = lazy(() =>
//   import("./modules/UserProfile/UserProfilePage")
// )

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/my-page" component={MyPage} />
        {/* Slideshow */}
        <ContentRoute path="/slideshow/nuevo" component={SlideshowEdit} />
        <ContentRoute path="/slideshow/:id/editar" component={SlideshowEdit} />
        <ContentRoute path="/slideshow" component={SlideshowPage} />
        {/* Curso Categoria */}
        <ContentRoute
          path="/curso/categoria/nuevo"
          component={CourseCategoryEdit}
        />
        <ContentRoute
          path="/curso/categoria/:id/editar"
          component={CourseCategoryEdit}
        />
        <ContentRoute path="/curso/categoria/" component={CourseCategoryPage} />
        {/* Curso */}
        <ContentRoute path="/curso/nuevo" component={CourseEdit} />
        <ContentRoute path="/curso/:id/editar" component={CourseEdit} />
        <ContentRoute path="/curso" component={CoursePage} />
        {/* Header */}
        <ContentRoute path="/header/:id/editar" component={HeaderEdit} />
        <ContentRoute path="/header" component={HeaderPage} />
        <Redirect to="error/error-v1" />
        <ContentRoute path="/logout" component={Logout} />
      </Switch>
    </Suspense>
  );
}

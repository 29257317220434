/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { shallowEqual, useSelector } from "react-redux";
import * as actions from "../_redux/coursesActions";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { CourseEditForm } from "./CourseEditForm";
import { useSubheader } from "../../../../_metronic/layout";
import { ModalProgressBar } from "../../../../_metronic/_partials/controls";
import moment from "moment";

const initCourse = {
  id: undefined,
  file: null,
  file2: null,
  courses_categories_id: "",
  title: "",
  date: "", //moment().format("DD-MM-YYYY"),
  duration: "",
  description: "",
  short_description: "",
  targets: "",
  customers: "",
  includes: "",
  btn_label: "",
  btn_url: "",
};

export function CourseEdit({
  history,
  match: {
    params: { id },
  },
}) {
  // Subheader
  const suhbeader = useSubheader();

  // Tabs
  // const [tab, setTab] = useState("basic");
  const [title, setTitle] = useState("");
  const dispatch = useDispatch();
  // const layoutDispatch = useContext(LayoutContext.Dispatch);
  const { actionsLoading, courseForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.courses.actionsLoading,
      courseForEdit: state.courses.courseForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetchCourse(id));
  }, [id, history, dispatch]);

  useEffect(() => {
    let _title = id ? "" : "Nuevo Curso";

    if (courseForEdit && id) {
      _title = "Editar Curso";
    }

    setTitle(_title);
    suhbeader.setTitle(_title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseForEdit, id]);

  const saveCourse = (values) => {
    // console.log("data3", values);
    let formData = new FormData();
    formData.append("category", values.courses_categories_id);
    formData.append("title", values.title);
    formData.append("date", moment(values.date).format("YYYY-MM-DD"));
    formData.append("duration", values.duration);
    formData.append("description", values.description);
    formData.append("short_description", values.short_description);
    formData.append("targets", values.targets);
    formData.append("customers", values.customers);
    formData.append("includes", values.includes);
    formData.append("btn_label", values.btn_label);
    formData.append("btn_url", values.btn_url);

    if (values.file) {
      formData.append("image", values.file);
    }

    if (values.file2) {
      formData.append("image_details", values.file2);
    }

    if (!id) {
      dispatch(actions.createCourse(formData)).then(() => backToCoursesList());
    } else {
      formData.append("id", values.id);
      dispatch(actions.updateCourse(formData)).then(() => backToCoursesList());
    }
  };

  const btnRef = useRef();
  const saveCourseClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };

  const backToCoursesList = () => {
    history.push(`/curso/listado`);
  };

  return (
    <Card>
      {actionsLoading && <ModalProgressBar />}
      <CardHeader title={title}>
        <CardHeaderToolbar>
          <button
            type="button"
            onClick={backToCoursesList}
            className="btn btn-light"
          >
            <i className="fa fa-arrow-left"></i>
            Regresar
          </button>
          {`  `}
          <button
            type="submit"
            className="btn btn-primary ml-2"
            onClick={saveCourseClick}
          >
            Guardar
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="mt-5">
          {/* {tab === "basic" && ( */}
          <CourseEditForm
            actionsLoading={actionsLoading}
            course={courseForEdit || initCourse}
            btnRef={btnRef}
            saveCourse={saveCourse}
          />
        </div>
      </CardBody>
    </Card>
  );
}

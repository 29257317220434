import React from "react";
import {
  CourseStatusCssClasses,
  CoursesStatusTitles,
} from "../../CoursesUIHelpers";

export const StatusColumnFormatter = (cellContent, row) => (
  <span
    className={`label label-lg label-light-${
      CourseStatusCssClasses[row.status]
    } label-inline`}
  >
    {CoursesStatusTitles[row.status]}
  </span>
);

import React from "react";
import {
  CourseCategoryStatusCssClasses,
  CoursesCategoryStatusTitles,
} from "../../CoursesCategoryUIHelpers";

export const StatusColumnFormatter = (cellContent, row) => (
  <span
    className={`label label-lg label-light-${
      CourseCategoryStatusCssClasses[row.status]
    } label-inline`}
  >
    {CoursesCategoryStatusTitles[row.status]}
  </span>
);

import React from "react";
import { useSubheader } from "../../../_metronic/layout";
import { HeadersLoadingDialog } from "./headers-loading-dialog/HeadersLoadingDialog";
import { HeadersUIProvider } from "./HeadersUIContext";
import { HeadersCard } from "./HeadersCard";

export default function HeaderPage({ history }) {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Header");

  const HeaderUIEvents = {
    newHeaderButtonClick: () => {
      history.push("/header/nuevo");
    },
    openEditHeaderPage: (id) => {
      history.push(`/header/${id}/editar`);
    },
  };

  return (
    <HeadersUIProvider headersUIEvents={HeaderUIEvents}>
      <HeadersLoadingDialog />
      <HeadersCard />
    </HeadersUIProvider>
  );
}

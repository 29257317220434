import * as requestFromServer from "./coursesCrud";
import { coursesSlice, callTypes } from "./coursesSlice";

const { actions } = coursesSlice;

export const fetchCourses = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findCourses(queryParams)
    .then((response) => {
      const { count, data } = response.data;
      dispatch(actions.coursesFetched({ count, data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find courses";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchCourse = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.courseFetched({ courseForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getCourseById(id)
    .then((response) => {
      const course = response.data;
      dispatch(actions.courseFetched({ courseForEdit: course }));
    })
    .catch((error) => {
      error.clientMessage = "No puedo encontrar el curso";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteCourse = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteCourse(id)
    .then((response) => {
      dispatch(actions.courseDeleted({ id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete course";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createCourse = (courseForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createCourse(courseForCreation)
    .then((response) => {
      const { course } = response.data;
      dispatch(actions.courseCreated({ course }));
    })
    .catch((error) => {
      error.clientMessage = "No se puede crear el curso";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateCourse = (course) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateCourse(course)
    .then(() => {
      dispatch(actions.courseUpdated({ course }));
    })
    .catch((error) => {
      console.log("error", error);
      error.clientMessage = "No se puede actualizar el curso";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

// export const updateProductsStatus = (ids, status) => dispatch => {
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .updateStatusForProducts(ids, status)
//     .then(() => {
//       dispatch(actions.productsStatusUpdated({ ids, status }));
//     })
//     .catch(error => {
//       error.clientMessage = "Can't update products status";
//       dispatch(actions.catchError({ error, callType: callTypes.action }));
//     });
// };

// export const deleteProducts = ids => dispatch => {
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .deleteProducts(ids)
//     .then(() => {
//       dispatch(actions.productsDeleted({ ids }));
//     })
//     .catch(error => {
//       error.clientMessage = "Can't delete products";
//       dispatch(actions.catchError({ error, callType: callTypes.action }));
//     });
// };
